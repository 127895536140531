<template>
  <v-app dense fixed>
    <div
      v-bind:class="{
        bgSvg: !$vuetify.theme.dark,
        bgSvgDark: $vuetify.theme.dark
      }"
    >
      <v-flex
        class="backDropper "
        v-bind:style="{
          'color:rgb(255, 255, 255,0.5)': !$vuetify.theme.dark,
          'color:rgb(0,0,0)': $vuetify.theme.dark
        }"
      >
        <v-row class="pa-2 ma-0 pt-5 " style="{overflow:hidden}">
          <v-col align-self="auto" cols="12" md="4" class="pa-0 ma-0">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card
                  v-if="userInfo"
                  class=" text-center  mx-2 mt-0"
                  :elevation="hover ? 15 : 6"
                  route
                  to="Profiles"
                >
                  <v-avatar color="grey" size="50" elevation-12 class="mt-n4">
                    <v-btn class="white buttonGlass" icon large>
                      <v-icon color="grey"> fas fa-user</v-icon></v-btn
                    >
                  </v-avatar>
                  <v-card-title class="layout justify-center mt-n3">
                    {{ beautifyWord(userInfo.name) }}</v-card-title
                  >
                  <v-card-text
                    class="layout justify-center mt-n3 mb-2 text--lighten-2"
                  >
                    {{ userInfo.nulm }}
                    {{ userInfo.district }}</v-card-text
                  >
                </v-card>
              </template></v-hover
            >
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card
                  :elevation="hover ? 15 : 6"
                  class=" text-center mt-8 mx-2"
                  route
                  to="Notifications/nothing"
                >
                  <v-avatar color="grey" size="50" elevation-12 class="mt-n4">
                    <v-btn class="white buttonGlass" icon large>
                      <v-icon color="grey">
                        fas fa-envelope-open-text
                      </v-icon></v-btn
                    >
                  </v-avatar>

                  <v-card-title class="layout justify-center">
                    Notifications</v-card-title
                  >
                  <v-card-subtitle class="subtitle-2 mx-n2">
                    You have
                    {{ userNotifications.length }}
                    notifications</v-card-subtitle
                  ></v-card
                >
              </template>
            </v-hover>
            <v-card
              elevation="6"
              class=" text-center mt-10 mb-10 mx-2"
              style="overflow:show"
            >
              <v-avatar color="grey" size="50" elevation-12 class="mt-n4">
                <v-btn class="white buttonGlass" icon large>
                  <v-icon color="grey"> fas fa-chart-pie</v-icon></v-btn
                >
              </v-avatar>
              <v-card-title class="layout justify-center mb-0 pa-0 pt-2">
                Users Usage</v-card-title
              >
              <v-sparkline
                style=""
                height="150"
                :auto-draw="true"
                :auto-draw-duration="2000"
                :label-size="7"
                color="blue-grey lighten-3"
                class="ma-0  px-4 "
                :value="value"
                :labels="keys"
                min-width="100%"
                min-height="100%"
                line-width="7"
                padding="16"
                :smooth="true"
                :show-labels="true"
                :fill="false"
              >
                <template v-slot:label="item" class="mr-n6 white-text">
                  {{ dateMonth(item.value) }}-{{ value[item.index] }}
                </template>
              </v-sparkline>
            </v-card>
          </v-col>

          <v-col align-self="auto" cols="12" md="4" class="mx-0 pa-0">
            <v-card
              class=" text-center mx-2 pa-4 mt-0 pt-0 mb-10"
              elevation="6"
              v-if="userInfo"
            >
              <v-avatar color="grey" size="50" elevation-12 class="mt-n4">
                <v-btn class="white buttonGlass" icon large>
                  <v-icon color="grey"> fas fa-history</v-icon></v-btn
                >
              </v-avatar>
              <v-timeline
                v-for="item in userInfo.timeLineItem"
                v-bind:key="item.name"
                dense
                class=""
              >
                <v-timeline-item class="" color="blue-grey lighten-3">
                  <v-row class="mt-n1 text--lighten-2">
                    {{ item.name }}
                  </v-row>
                  <v-row class="mt-n2">
                    <v-col>
                      <h5>
                        {{
                          displayTime(item.date)
                            .toString()
                            .replace('GMT+0530 (India Standard Time)', '')
                        }}
                      </h5></v-col
                    >
                  </v-row>
                </v-timeline-item>
              </v-timeline>
            </v-card>
            <v-hover v-if="['CMMU', 'SMMU'].includes(userInfo.nulm)">
              <template v-slot:default="{ hover }">
                <v-card
                  to="User"
                  :elevation="hover ? 15 : 6"
                  class=" text-center mb-10 mx-2"
                >
                  <v-avatar color="grey" size="50" elevation-12 class="mt-n4">
                    <v-btn class="white buttonGlass" icon large>
                      <v-icon color="grey"> fas fa-tasks</v-icon></v-btn
                    >
                  </v-avatar>
                  <v-card-title class="layout justify-center mt-n3" dense
                    >Approval</v-card-title
                  >
                  <v-list class="mt-n7">
                    <v-list-item>
                      <v-list-item-title
                        class="primary--text text--darken-1 py-1"
                      >
                        Users Pending
                      </v-list-item-title>
                      <v-list-item-title class="primary--text text--darken-1">
                        Users Approved
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item class="mt-n6">
                      <v-list-item-subtitle class="red--text text--darken-2">
                        {{ userUnapproved.length }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        {{ userInfo.approved || 0 }}
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-card>
              </template>
            </v-hover>
          </v-col>
          <v-col align-self="start" cols="12" md="4" class="mx-0 pa-0">
            <v-card class=" text-center mx-2 pa-4 pt-0 mt-0" elevation="6">
              <v-avatar color="grey" size="50" elevation-12 class="mt-n4">
                <v-btn
                  class="white buttonGlass"
                  :class="{ 'grey lighten-3': $vuetify.theme.dark }"
                  icon
                  large
                >
                  <v-icon color="grey"> fas fa-th</v-icon></v-btn
                >
              </v-avatar>
               
              <v-toolbar class="pa-1 elevation-1">
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="calendarAdd = true" class="buttonGlass"
                  ><v-icon color="warning darken-1"
                    >fas fa-calendar-plus</v-icon
                  ></v-btn
                >

                <v-dialog
                  v-model="todayEventsDialog "
                  :fullscreen="$vuetify.breakpoint.mobile"
                  :hide-overlay="false"
                  :max-width="checkDimensions('50', 'vw')"
                  :min-width="checkDimensions('50', 'vw')"
                >
                  <v-card class="pa-10 ma-0" just>
                    <v-btn
                      class="mt-2"
                      absolute
                      right
                      icon
                      v-if="$vuetify.breakpoint.mobile"
                      @click.prevent="todayEventsDialog = !todayEventsDialog"
                    >
                      <v-icon>
                        fas fa-times-circle
                      </v-icon>
                    </v-btn>
                    <v-card-title dark>
                      <div class="text-center">Event Reminder For Today</div>
                    </v-card-title>
                    <v-card
                      class="mt-0 mx-2 pa-0 mb-4"
                      outlined
                      v-for="(item, index) in userEventWatch"
                      :key="index"
                    >
                  
                      <v-card-text>
                        <p class="text-h5 primary--text ma-0 pa-0">
                          {{ item.name }}
                        </p>
                        <p>{{ item.details }}</p>
                        <div class="text--primary ma-0 pa-0">
                          {{ endStart(item.start) }} atanga
                          {{ endStart(item.end) }} thleng he Reminder hi kam
                          lawk a ni
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-card></v-dialog
                >

                <v-dialog
                  v-model="calendarAdd"
                  :fullscreen="$vuetify.breakpoint.mobile"
                  :hide-overlay="false"
                  overlay-opacity="0.8"
                  overlay-color="black"
                  :max-width="checkDimensions('50', 'vw')"
                  :min-width="checkDimensions('50', 'vw')"
                  ><v-card class="pa-5 ma-0">
                    <v-btn
                      style="{z-index:5}"
                      class="mt-2"
                      absolute
                      right
                      icon
                      v-if="$vuetify.breakpoint.mobile"
                      @click.prevent="calendarAdd = !calendarAdd"
                    >
                      <v-icon>
                        fas fa-times-circle
                      </v-icon>
                    </v-btn>
                    <div class="text-center text-h4 text-decoration-underline">Enter details of the event</div>
<validation-observer v-slot="eventsInvalid">
<v-form
                      class="pt-5"
                      :class="{ 'pt-10': $vuetify.breakpoint.mobile }"
                    >
                      <v-row>
                        <v-col cols="12" md="6">
                        <validation-provider name="Event I siam tur hming" v-slot="{errors}" rules="required">
                         <v-text-field
                            v-model="eventName"
                            class="mx-8"
                            label="Name of Event"
                            prepend-icon="fas fa-calendar"
                            type="text"
                            color="primary accent-3"
                            :error-messages="errors"
                          ></v-text-field
                        >
                        </validation-provider>
                         </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="eventDesc"
                            class="mx-8"
                            label="Description of Event"
                            prepend-icon="fas fa-calendar-day"
                            type="text"
                            color="primary accent-3"
                            hint="I Event siam tur hriat reng theih nan ziahchian a tha"
                          ></v-text-field
                        ></v-col>

                        <v-col cols="12" md="6">
                          <v-menu
                            ref="menu"
                            transition="scale-transition"
                            offset-y
                            :close-delay="2000"
                            v-model="eventStartTrig"
                            min-width="290px"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                             <validation-provider name="Event tan hun tur ni" v-slot="{errors}" rules="required">

                              <v-text-field
                               
                                v-model="eventStartFinal"
                                class="mx-8"
                                label="Select the starting Date"
                                prepend-icon="fas fa-calendar-check"
                                type="text"
                                color="primary accent-3"
                                :error-messages="errors"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field
                            >
                             </validation-provider>
                            </template>
                            
                            <v-date-picker
                              v-model="eventStart"
                              color="primary"
                              @input="eventStartTrig = false"
                              min="1950-01-01"
                              @change="dateToIst(eventStart, 'start')"
                            ></v-date-picker> </v-menu
                        >
                  
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-menu
                            v-model="timeStartTrig"
                            transition="scale-transition"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                             <validation-provider name="Event tan hun tur dar zat" v-slot="{errors}" rules="required">

                              <v-text-field
                                v-model="eventStartTime"
                                class="mx-8"
                                label="Select the starting Time"
                                prepend-icon="fas fa-clock"
                                type="text"
                                color="primary accent-3"
                                :error-messages="errors"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                             </validation-provider>
                            </template>
                            <v-time-picker
                              class="ma-0 pa-0"
                              @change="timePickClose('start')"
                              v-model="eventStartTime"
                              format="24hr"
                            ></v-time-picker> </v-menu
                        ></v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-menu
                            ref="menu"
                            transition="scale-transition"
                            offset-y
                            :close-delay="2000"
                            v-model="eventEndTrig"
                            min-width="290px"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on, attrs }">
                             <validation-provider name="Event tawp hun tur ni" v-slot="{errors}" rules="required">

                              <v-text-field
                                v-model="eventEndFinal"
                                class="mx-8"
                                :error-messages="errors"
                                label="Select the Ending Date"
                                prepend-icon="fas fa-calendar-week"
                                type="text"
                                color="primary accent-3"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field
                            >
                             </validation-provider>
                            </template>
                            <v-date-picker
                              :min="eventStart"
                              v-model="eventEnd"
                              @input="eventEndTrig = false"
                              color="primary"
                              @change="dateToIst(eventEnd, 'end')"
                            ></v-date-picker> </v-menu
                        ></v-col>
                        <v-col
                          ><v-menu
                           
                            v-model="timeEndTrig"
                            :close-on-content-click="false"
                            transition="scale-transition"
                           
                          >
                            <template v-slot:activator="{ on, attrs }">
                             <validation-provider name="Event tawp hun tur dar zat" v-slot="{errors}" rules="required">

                              <v-text-field
                              v-model="eventEndTime"
                              :error-messages="errors"
                                class="mx-8"
                                label="Select the starting Time"
                                prepend-icon="fas fa-clock"
                                type="text"
                                color="primary accent-3"
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                             </validation-provider>
                            </template>
                            <v-time-picker
                              format="24hr"
                              v-model="eventEndTime"
                              @change="timePickClose('end')"
                            ></v-time-picker> </v-menu
                        ></v-col>
                      </v-row>
                      <div class="text-center">
                        <v-btn
                        :disabled="!eventsInvalid.valid"
                          text
                          dark
                          @click="eventSubmit()"
                          class="ma-5 primary buttonGlass"
                          >Submit</v-btn
                        >
                      </div>
                    </v-form>
                    
</validation-observer>
                    </v-card
                  ></v-dialog
                >
                <v-dialog
                  v-model="calendarDialog"
                  :hide-overlay="false"
                  max-width="60vw"
                >
                  <v-card class="pa-4">
                    <v-card-title> {{ name }}</v-card-title>
                    <v-divider class="pt-4"></v-divider>

                    <v-card-subtitle>{{ details }}</v-card-subtitle>

                    <v-card-subtitle
                      >"{{ endStart(start) }}" atanga "{{
                        endStart(end)
                      }}"</v-card-subtitle
                    >
                  </v-card>
                </v-dialog>
              </v-toolbar>
              <v-btn
                icon
                class="ma-2 text--start"
                @click="$refs.calendar.prev()"
              >
                <v-icon>fas fa-chevron-left</v-icon>
              </v-btn>

              <v-btn icon class="ma-2 text--end" @click="$refs.calendar.next()">
                <v-icon>fas fa-chevron-right</v-icon>
              </v-btn>

              <v-calendar
                v-if="userInfo"
                ref="calendar"
                :events="userInfo.events"
                v-model="focus"
                color="blue-grey lighten-3"
                text-color="white"
                category-show-all
                absolute
              >
                <template v-slot:event="{ event }">
                  <div
                    :style="{
                      'background-color': colorRet(event, userInfo.events),
                      color: 'white'
                    }"
                    class="fill-height pl-2"
                    @click="
                      calendarDialogFunctions(
                        event.name,
                        event.start,
                        event.end,
                        event.details
                      )
                    "
                  >
                    {{ event.name }}
                  </div>
                </template></v-calendar
              >
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </div>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import {ValidationObserver,ValidationProvider,setInteractionMode,extend} from 'vee-validate'
import{required} from 'vee-validate/dist/rules'
// import moment from 'moment'
setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_} a ruak theilo'
})
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

export default {
  components:{
    ValidationObserver,ValidationProvider
  },
  data: () => ({
    todayEventsDialog: false,

    timeEndTrig: false,
    timeStartTrig: false,
    eventStartTrig: false,
    eventEndTrig: false,
    eventStartTime: '',
    eventEndTime: '',
    userEvents: [],
    value: [],
    keys: [],
    startMenu: false,
    menu: false,
    eventName: '',
    eventDesc: '',
    eventStartFinal: '',
    eventStart: '',
    eventEnd: '',
    eventEndFinal: '',
    eventColor: '',
    calendarAdd: false,
    name: '',
    start: '',
    end: '',
    details: '',
    calendarDialog: false,
    focus: '',
    newCounter: []
  }),

  computed: {
    userEventWatch () {
      let retArr = []
      let now = firebase.firestore.Timestamp.now().seconds * 1000
     
      
      let events = this.$store.state.userInfo.events
      if (events) {
        events.forEach(async e => {
          if (e.start && e.end)
            if ((e.start, now, e.end, e.start < now && e.end > now))
              
          retArr.push(e)
        })
      }
      if (retArr) {
       
          return retArr
       
      } else return []
    },

    ...mapState(['userInfo', 'userNotifications', 'userUnapproved'])
  },
  mounted () {
    this.$refs.calendar.checkChange()
  },
  methods: {
    colorRet (e, events) {
      let items = ['red', '#2196f3', 'yellow', 'pink']
      let i
      events.forEach((event, index) => {
        if ((index, event === e)) i = index % items.length
      })

      // let item = items[Math.floor(Math.random()*items.length)];
      return items[i]
    },
    recDate () {
      let d =
        new Date(firebase.firestore.Timestamp.now().seconds * 1000) ||
        new Date(Date.now())
      let imp = d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear()
      let upload = {
        [imp]: 1
      }
      let upload2 = {
        [imp]: firebase.firestore.FieldValue.increment(1)
      }
      let last = localStorage.getItem('lastAccesses')

      if (last != imp) {
         this.todayEventsDialog = true
        localStorage.setItem('lastAccesses', imp)

        firebase
          .firestore()
          .collection('counter')
          .doc('counter')
          .update(upload2)
          .then(
            firebase
              .firestore()
              .collection('counter')
              .doc('counter')
              .get()
              .then(async doc => {
                ((this.keys = Object.keys(doc.data())),
                (this.keys = this.keys.sort((a, b) => {
                  return (
                    new Date(
                      a.split('-')[2],
                      a.split('-')[1],
                      a.split('-')[0]
                    ).getTime() -
                    new Date(
                      b.split('-')[2],
                      b.split('-')[1],
                      b.split('-')[0]
                    ).getTime()
                  )
                }))),
                  (this.keys = this.keys.slice(this.keys.length - 6))
                this.keys.pop()
                this.keys.forEach(async e => {
                  this.value.push(doc.data()[e])
                })
                localStorage.setItem(
                  'userVisits',
                  JSON.stringify(this.keys) + '/' + JSON.stringify(this.value)
                )
              })
          )
          .catch(err => {
            if (err.code === 'not-found') {
              firebase
                .firestore()
                .collection('counter')
                .doc('counter')
                .update(upload)
            }
          })
      } else {
        this.keys = JSON.parse(localStorage.getItem('userVisits').split('/')[0])
        this.value = JSON.parse(
          localStorage.getItem('userVisits').split('/')[1]
        )
      }
    },
    dateMonth (input) {
      switch (input.split('-')[1]) {
        case '1':
          return `${input.split('-')[0]} Jan`
        case '2':
          return `${input.split('-')[0]} Feb`
        case '3':
          return `${input.split('-')[0]} Mar`
        case '4':
          return `${input.split('-')[0]} Apr`
        case '5':
          return `${input.split('-')[0]} May`
        case '6':
          return `${input.split('-')[0]} Jun`
        case '7':
          return `${input.split('-')[0]} Jul`
        case '8':
          return `${input.split('-')[0]} Aug`
        case '9':
          return `${input.split('-')[0]} Sep`
        case '10':
          return `${input.split('-')[0]} Oct`
        case '11':
          return `${input.split('-')[0]} Nov`
        case '12':
          return `${input.split('-')[0]} Dec`
      }
    },
    endStart (input) {
      let ret = new Date(input)
      return String(ret).replace('GMT+0530 (India Standard Time)', '')
    },
    timePickClose (init) {
      if (init === 'start') this.timeStartTrig = false
      if (init === 'end') this.timeEndTrig = false
    },
    dateToIst (date, col) {
      if (date.length > 0) {
        if (col === 'start') {
          this.eventStartFinal = `${date.split('-')[2]}-${date.split('-')[1]}-${
            date.split('-')[0]
          }`
        } else if (col == 'end') {
          this.eventEndFinal = `${date.split('-')[2]}-${date.split('-')[1]}-${
            date.split('-')[0]
          }`
        }
      }
    },
    checkDimensions (amount, verHori) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'md':
          if (verHori === 'vh') {
            return '90vh'
          } else return '90vw'

        default:
          return `${amount}${verHori}`
      }
    },
    beautifyWord (valueWord) {
      if (valueWord != undefined && valueWord) {
        let value = valueWord
          .toString()
          .trim()
          .split(' ')
        let final = ''
        for (let i = 0; i < value.length; i++) {
          let valueTemp = value[i]

          if (i < value.length) {
            final =
              final +
              valueTemp[0].toUpperCase() +
              valueTemp.substring(1).toLowerCase() +
              ' '
          } else {
            final =
              final +
              valueTemp[0].toUpperCase() +
              valueTemp.substring(1).toLowerCase()
          }
        }
        return final
      }
    },
    displayTime (ts) {
      return ts.toDate()
      // moment(ts.seconds * 1000).format("MMMM Do YYYY, h:mm:ss a");
    },
    eventSubmit () {
      let start = new Date(this.eventStart + ' ' + this.eventStartTime)
      start = start.getTime()

      let end = new Date(this.eventEnd + ' ' + this.eventEndTime)
      end = end.getTime()
      let data = {
        name: this.eventName,
        details: this.eventDesc,
        start,
        end
      }
      firebase
        .firestore()
        .collection(localStorage.getItem('userDb'))
        .doc(firebase.auth().currentUser.uid)
        .update({
          events: firebase.firestore.FieldValue.arrayUnion(data)
        })
      this.calendarAdd = false
      this.eventName = this.eventDesc = this.eventStartTime = this.eventEnd = this.eventEndTime =
        ''
    },
    calendarDialogFunctions (name, start, end, details) {
      this.name = name
      this.start = start
      this.end = end
      this.details = details
      this.calendarDialog = true
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    saveStart (date) {
      this.$refs.menu.save(date)
    },

    functionEvents (date) {
      const [, , day] = date.split('-')
      if ([12, 17, 28].includes(parseInt(day, 10))) return true
      if ([1, 19, 22].includes(parseInt(day, 10))) return ['red', '#00f']
    }
  },
  async created () {
    this.recDate()
      let lastNotiSeen = localStorage.getItem("lastNotiSeen")
    let now = firebase.firestore.Timestamp.now().seconds * 1000
      let d = new Date(now)
      let final = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`
    if(lastNotiSeen){
       
      if(final!=lastNotiSeen){
       this.todayEventsDialog = true
        localStorage.setItem("lastNotiSeen",final)
      }
      } else{
         this.todayEventsDialog = true
        localStorage.setItem("lastNotiSeen",final)
      }
  },

  watch: {
    // userEventWatch (e) {
    //   e
    //   let now = firebase.firestore.Timestamp.now().seconds * 1000
    //   let d = new Date(now)
    //   let final = `${d.getDate() + 1}-${d.getMonth() + 1}-${d.getFullYear()}`
    //   localStorage.setItem('lastNotiSeen', final)
    //   if (e.length > 0) this.todayEventsDialog = true
    // }
  },
  beforeRouteEnter (to, from, next) {
    if (localStorage.getItem('approved') > 0) {
      firebase
        .firestore()
        .collection(localStorage.getItem('userDb'))
        .doc(firebase.auth().currentUser.uid)
        .update({
          approved: firebase.firestore.FieldValue.increment(
            localStorage.getItem('approved')
          )
        })
        .then(() => {
          localStorage.setItem('approved', 0)
          next()
        })
        .catch(err =>
          next(vm => {
            vm.$store.commit('changeError', err)
            next()
          })
        )
    } else {
      next()
    }
  }
}
</script>
<style scoped>
.rounded {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.bgSvg {
  min-height: 100vh;
  background-color: #fff;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='111' height='111' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23cacaca' fill-opacity='0.46'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.46'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.bgSvgDark {
  min-height: 100vh;

  background-color: #07070748;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23060606' %3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='1'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.backDropper {
  min-height: 100vh;

  backdrop-filter: blur(15px);
}
.buttonGlass {
  box-sizing: border-box;
  overflow: hidden;
}
.buttonGlass::before {
  position: absolute;
  left: 0%;
  width: 20%;
  backdrop-filter: blur(2px);
  transform: skewX(-40deg) translateX(-3rem);
  background-color: white;
  opacity: 0.4 !important;
  z-index: 2;
  transition: 0.4s ease-out;
  border-radius: none !important;
}
.buttonGlass:hover::before {
  left: 100%;
  transform: skewX(-40deg) translateX(1rem);
}
</style>
